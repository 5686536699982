import React from 'react';
import { Box, Flex, Text, Image, Heading } from '@chakra-ui/core';
import { colors } from '@theme';
import { H2, H4 } from '@typography';
import Link from '../../Navigation/Link';

import {
  CURRENT_APY_RATE,
  CURRENT_VIP_APY_RATE,
  cloudfrontBaseUrl,
} from '../../lib/consts';

import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import { APP_CONTENT_DESKTOP_WIDTH } from '../../styles/sizes';

const CardFeatures = ({
  subtitle = 'Your Zeta account also includes these features:',
}) => {
  return (
    <Flex py={[10, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[10, 16]}
        px={[4, 0]}
      >
        <DesktopOnly>
          <H2 center>There's even more to love</H2>
        </DesktopOnly>

        <MobileOnly>
          <H2 center>There's more to love</H2>
        </MobileOnly>
        <H4 mt={4} center>
          {subtitle}
        </H4>
      </Flex>
      <Flex
        direction={['column', 'row']}
        wrap={['auto', 'wrap']}
        maxWidth={APP_CONTENT_DESKTOP_WIDTH}
      >
        <Flex
          px={5}
          py={[2, 5]}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_1.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']} textAlign={['center', 'left']}>
            Virtual cards
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Virtual cards for added convenience, privacy, and control
          </Text>
        </Flex>
        <Flex
          px={5}
          py={[6, 5]}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_2.png`}
          />
          <Heading
            width={['20rem', 'auto']}
            my={[3, 4]}
            as="h4"
            fontSize={['md']}
            textAlign={['center', 'left']}
          >
            No monthly account fees
          </Heading>
          <Text mt={[-1, 0, 0]} textAlign={['center', 'left']} width={['60%', '100%']}>
            Full transparency; no monthly fees or minimums (other{' '}
            <Link style={{ color: colors.greenPea }} href="/fees">
              fees
            </Link>{' '}
            may apply)
          </Text>
        </Flex>
        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_6.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']}>
            Zeta Concierge
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            First class human support via in-app messaging for when you need it
          </Text>
        </Flex>
        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_4.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']}>
            Instant Notifications
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Get updates when anyone on your account spends, deposits, or gets a refund
          </Text>
        </Flex>

        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_9.png`}
          />
          <Heading py={[2, 0, 0]} my={[3, 4]} as="h4" fontSize={['md']}>
            ATMs anywhere
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Use your Zeta debit card at any ATM
          </Text>
        </Flex>
        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_12.png`}
          />
          <Heading pb={[3, 0, 0]} my={[3, 4]} as="h4" fontSize={['md']}>
            Bill pay
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Automatically organize & set aside money for bills with the option to pay via
            ACH, check, or card
          </Text>
        </Flex>
        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_7.png`}
          />
          <Heading pt={[2, 0, 0]} pb={[1, 0, 0]} my={[3, 4]} as="h4" fontSize={['md']}>
            Mobile check deposit
          </Heading>
          <Text mt={[-2, 0, 0]} textAlign={['center', 'left']} width={['60%', '100%']}>
            Snap a photo and deposit that check with just a few clicks
          </Text>
        </Flex>
        <Flex
          px={5}
          py={5}
          width={['100%', '33%', '25%']}
          flexDirection={['column']}
          alignItems={['center', 'flex-start']}
        >
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_8.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']}>
            Pay someone
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Easily send payments to individuals or businesses with Zeta Pay, ACH, wires,
            and checks
          </Text>
        </Flex>

        {/* <Box px={5} py={5} width={['100%', '33%', '25%']}  flexDirection={['column']} alignItems={['center', 'flex-start']}>
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_10.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']}>
            Contactless payment
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>Add us to any digital wallet like Apple Pay, Google Pay, or PayPal</Text>
        </Box> */}
        {/* <Box px={5} py={5} width={['100%', '33%', '25%']}  flexDirection={['column']} alignItems={['center', 'flex-start']}>
          <Image
            height="2.5rem"
            src={`${cloudfrontBaseUrl}/joint-accounts/account_features_11.png`}
          />
          <Heading my={[3, 4]} as="h4" fontSize={['md']}>
            Credit card support
          </Heading>
          <Text textAlign={['center', 'left']} width={['60%', '100%']}>
            Also have a credit card? Link it to Zeta so we can set aside money for when
            it's due
          </Text>
        </Box> */}
      </Flex>
    </Flex>
  );
};

export default CardFeatures;
