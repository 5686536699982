import React from 'react';
import { Text } from '@chakra-ui/core';

export default function H1({ children, center }) {
  return (
    <Text
      as="h1"
      textAlign={['center', center ? 'center' : 'left']}
      fontSize={['40px', '60px']}
      lineHeight="120%"
      fontWeight={600}
    >
      {children}
    </Text>
  );
}
