import React from 'react';
import { Text } from '@chakra-ui/core';

export default function H2({ children, center, ...props }) {
  return (
    <Text
      as="h2"
      textAlign={center ? 'center' : 'center'}
      fontSize={['32px', '48px']}
      lineHeight="120%"
      fontWeight={600}
      {...props}
    >
      {children}
    </Text>
  );
}
