import React from 'react';
import { Flex, Text } from '@chakra-ui/core';

import { CURRENT_APY_RATE } from '../../lib/consts';
import theme, { colors } from '@theme';

const Testimonial = () => {
  return (
    <Flex my={16} direction="column" px={4}>
      <Text color={colors.boulder} fontSize="xs">
        Banking services for the Zeta Joint Card and Joint Account provided by Piermont
        Bank, Member FDIC. Zeta Help Inc. is a service provider of the issuing bank. All
        deposit accounts of the same ownership and/or vesting held at the issue bank are
        combined and insured under an FDIC Certificate, up to $500,000. The Zeta Joint
        Debit Card, provided by MasterCard, may be used everywhere MasterCard Debit Cards
        are accepted.
      </Text>

      <br />
      <Text color={colors.boulder} fontSize="xs">
        Zeta Joint Account Annual Percentage Yield (APY) accurate as of 09/1/2022. Minimum
        amount to open account is $0.00. Minimum balance to earn APY is $0.01. Rate is as
        follows: {CURRENT_APY_RATE} APY applies to balances over $0.01. Rates may change
        after account is opened. Fees may reduce earnings.
      </Text>
    </Flex>
  );
};

export default Testimonial;
