import React from 'react';
import { Text } from '@chakra-ui/core';

import theme from '@theme';

export default function H4({ children, center, bold, ...rest }) {
  return (
    <Text
      as="h4"
      textAlign={center ? 'center' : 'left'}
      fontSize={['18px', '24px']}
      fontWeight={bold ? theme.fontWeights.semibold : theme.fontWeights.normal}
      {...rest}
    >
      {children}
    </Text>
  );
}
