import React from 'react';
import { Text as ChakraText } from '@chakra-ui/core';

export default function Text({ children, lineHeight, size, bold, style, ...rest }) {
  return (
    <ChakraText
      style={style}
      fontSize={size ?? ['16px', '18px']}
      lineHeight={lineHeight ?? '140%'}
      fontWeight={bold ? 600 : 400}
      {...rest}
    >
      {children}
    </ChakraText>
  );
}
