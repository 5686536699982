import React from 'react';
import { Text } from '@chakra-ui/core';

export default function H3({ children, style, noAlign, customSize = null, rest }) {
  return (
    <Text
      as="h3"
      textAlign={[noAlign ? 'left' : 'center', 'left']}
      style={style}
      fontSize={['24px', customSize ? customSize : '32px']}
      lineHeight="120%"
      fontWeight={600}
      {...rest}
    >
      {children}
    </Text>
  );
}
